import {defaultTheme} from 'react-admin';
import palette from "./palette";
import components from "./components";

const primaryColor = "#FF424D";


const theme = {
  ...defaultTheme,
  palette,
  components,
}

export default theme
